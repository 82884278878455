import { CartFab } from '@graphcommerce/magento-cart'
import { magentoMenuToNavigation } from '@graphcommerce/magento-category'
import { CustomerFab, CustomerMenuFabItem } from '@graphcommerce/magento-customer'
import { SearchLink } from '../Search/SearchLink'
import { WishlistFab, WishlistMenuFabItem } from '@graphcommerce/magento-wishlist'
import {
  DesktopNavBar,
  LayoutDefaultProps,
  iconCustomerService,
  iconHeart,
  NavigationFab,
  MenuFabSecondaryItem,
  PlaceholderFab,
  IconSvg,
  DesktopNavItem,
  NavigationProvider,
  useNavigationSelection,
  useMemoDeep,
} from '@graphcommerce/next-ui'
import { LayoutDefault } from '../LayoutDefault'
import { DesktopNavActions } from './DesktopNavActions'
import { NavigationOverlay } from '../Navigation/NavigationOverlay'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, Divider } from '@mui/material'
import { useRouter } from 'next/router'
import { Footer } from './Footer'
import { LayoutQuery } from './Layout.gql'
import { Logo } from './Logo'
import { StoreSwitcherLink } from '../Store/StoreSwitcherLink'
import { RichText } from '@graphcommerce/graphcms-ui'
import React from 'react'

const featuredNavigationConfig = {
    'es': [],
    'en': []
}
const sliceLimit = 90

export type LayoutNavigationProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function LayoutNavigation(props: LayoutNavigationProps) {
  const { footer, menu, children, ...uiProps } = props
  const selection = useNavigationSelection()
  const router = useRouter()
  const { locale = 'es' } = router
  const menuItems = menu?.items?.[0]?.children
  const configCategories = menuItems?.filter((item) => item?.url_path?.includes(featuredNavigationConfig[locale] as string))
  const featuredCategories = menuItems && menuItems.length > sliceLimit ? menuItems?.slice(0, sliceLimit).concat(configCategories ?? []) : menuItems
  const path = useRouter().asPath.split('?')[0]
  const canClickBack = path !==  '/'

  return (
    <>
      <NavigationProvider
        selection={selection}
        items={useMemoDeep(
          () => {
            const menuOptionsRoot = [
              <SearchLink
                href='/search'
                sx={(theme) => ({
                  width: `calc(100% - ${theme.spacing(4)})`,
                  m: 2,
                  mb: theme.spacings.xs,
                })}
                aria-label={i18n._(/* i18n */ 'Search...')}
              >
                <Trans id='Search...' />
              </SearchLink>,
              { id: 'home', name: <Trans id='Home' />, href: '/' },
              ...magentoMenuToNavigation(menu, true)
            ]
            /*
            const menuOptionsFeaturedCategories = featuredCategories?.map((item, idx) => (
              {
                id: `manual-item-${idx}`,
                href: `/${item?.url_path}`,
                name: item?.name ?? '',
              }
            ))
            */
            const menuOptionsFeaturedCategories = []
            const menuOptionsExtra = [
              { id: 'blog', name: 'Blog', href: '/blog' },
              <Divider sx={(theme) => ({ my: theme.spacings.xs })} />,
              <CustomerMenuFabItem key='account' guestHref='/account/signin' authHref='/account'>
                <Trans id='Account' />
              </CustomerMenuFabItem>,
              <MenuFabSecondaryItem
                key='service'
                icon={<IconSvg src={iconCustomerService} size='medium' />}
                href='/service'
              >
                <Trans id='Customer Service' />
              </MenuFabSecondaryItem>,
              <WishlistMenuFabItem key='wishlist' icon={<IconSvg src={iconHeart} size='medium' />}>
                <Trans id='Wishlist' />
              </WishlistMenuFabItem>,
              <Divider sx={(theme) => ({ my: theme.spacings.xs })} />,
              <StoreSwitcherLink showLabel={false} showIcon showFlag={false} sx={
                (theme) => ({ paddingLeft: theme.spacings.xs })
              } />
            ]

            return menuOptionsRoot.concat(menuOptionsFeaturedCategories ?? []).concat(menuOptionsExtra)
          },
          [ menu ],
        )}
      >
        <NavigationOverlay
          stretchColumns={false}
          variantSm='left'
          sizeSm='full'
          justifySm='start'
          itemWidthSm='70vw'
          variantMd='left'
          sizeMd='full'
          justifyMd='start'
          itemWidthMd='230px'
          mouseEvent='hover'
          itemPadding='md'
        />
      </NavigationProvider>

      <LayoutDefault
        {...uiProps}
        noSticky={router.asPath.split('?')[0] === '/'}
        beforeHeader={(footer?.showBeforeHeaderMessage && footer?.beforeHeaderMessage) &&
            <Box sx={{ position: 'sticky', zIndex: 1051, top: 0, backgroundColor: '#000', color: '#fff', padding: '5px 0', textAlign: 'center' }}>
                <RichText {...footer.beforeHeaderMessage} />
            </Box>
        }
        header={
          <>
            <Logo />
            <DesktopNavBar>
              {featuredCategories?.map((item) => (
                <DesktopNavItem key={item?.uid} href={`/${item?.url_path}`} sx={{
                  textTransform: 'uppercase',
                  fontSize: '16px',
                }}>
                  {item?.name}
                </DesktopNavItem>
              ))}

              <DesktopNavItem href='/blog' sx={{
                textTransform: 'uppercase',
                fontSize: '16px',
              }}>
                <Trans id='Blog' />
              </DesktopNavItem>
            </DesktopNavBar>

            <DesktopNavActions sx={{ columnGap: '0' }}>
              {!router.pathname.startsWith('/search') && (
                <SearchLink
                  href='/search'
                  aria-label={i18n._(/* i18n */ 'Search...')}
                  breakpoint='xl'
                />
              )}

              <WishlistFab icon={<IconSvg src={iconHeart} size='large' />} sx={{ width: '46px', height: '46px' }} />
              <CustomerFab guestHref='/account/signin' authHref='/account' sx={{ width: '46px', height: '46px' }} />
              <StoreSwitcherLink showLabel showFlag={false}  />
              {/* The placeholder exists because the CartFab is sticky but we want to reserve the space for the <CartFab /> */}
              <PlaceholderFab />
            </DesktopNavActions>
          </>
        }
        footer={<Footer footer={footer} />}
        cartFab={<CartFab />}
        menuFab={<NavigationFab onClick={() => selection.set([])} sx={{
          marginLeft: (canClickBack ? '25px' : 0)
        }} />}
      >
        {children}
      </LayoutDefault>
    </>
  )
}
